/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import Checkout from "./checkout"


const indexCheckout = () => {
  return <Checkout />
}

export default indexCheckout
